import dashboard from './dashboard';
import userManagement from './user-management';
import appManagement from './app-management';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, userManagement, appManagement]
};

export default menuItems;
