import { createSlice } from '@reduxjs/toolkit';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'dashboard',

    initialState: {
        basicData: {
            data: {},
            error: '',
            loading: false
        },
        ageChartData: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get basic analytics data
        basicDataRequestedAction: (state) => {
            state.basicData.loading = true;
        },

        basicDataSuccessAction: (state, action) => {
            state.basicData.loading = false;
            state.basicData.data = action.payload;
        },

        basicDataFailedAction: (state, action) => {
            state.basicData.loading = false;
            state.basicData.error = action.payload;
        },

        // get age chart data
        ageChartDataRequestedAction: (state) => {
            state.ageChartData.loading = true;
        },

        ageChartDataSuccessAction: (state, action) => {
            state.ageChartData.loading = false;
            state.ageChartData.data = action.payload;
        },

        ageChartDataFailedAction: (state, action) => {
            state.ageChartData.loading = false;
            state.ageChartData.error = action.payload;
        }
    }
});

export default slice.reducer;

export const {
    // get basic analytics data
    basicDataRequestedAction,
    basicDataSuccessAction,
    basicDataFailedAction,

    // get age chart data
    ageChartDataRequestedAction,
    ageChartDataSuccessAction,
    ageChartDataFailedAction
} = slice.actions;

export const getBasicAnalyticsData = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/dashboard',
            method: 'GET',
            onStart: basicDataRequestedAction.type,
            onSuccess: basicDataSuccessAction.type,
            onError: basicDataFailedAction.type
        })
    );
};

export const getAgeChartData = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/dashboard/age-chart',
            method: 'GET',
            params: payload.params,
            onStart: ageChartDataRequestedAction.type,
            onSuccess: ageChartDataSuccessAction.type,
            onError: ageChartDataFailedAction.type
        })
    );
};
