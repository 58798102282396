import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { Alert, Snackbar } from '@mui/material';

// project imports
import { closeAlertRequestedAction } from 'store/reducers/common.reducer';
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const dispatch = useDispatch();
    const alertData = useSelector((state) => state.common.alert);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeAlertRequestedAction());
    };

    return (
        <>
            <Snackbar open={alertData.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertData.severity} variant="filled" sx={{ width: '100%', color: 'white' }}>
                    {alertData.message}
                </Alert>
            </Snackbar>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
