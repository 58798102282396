import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'gratitude',

    initialState: {
        gratitudesList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        createGratitude: {
            data: {},
            error: '',
            loading: false
        },
        deleteGratitudes: {
            data: {},
            error: '',
            loading: false
        },
        gratitudeVisibility: {
            data: {},
            error: '',
            loading: false
        },
        editGratitude: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get list of gratitudes
        gratitudesListRequestedAction: (state) => {
            state.gratitudesList.loading = true;
        },

        gratitudesListSuccessAction: (state, action) => {
            state.gratitudesList.loading = false;
            state.gratitudesList.data = action.payload;
            state.gratitudesList.list = get(action, 'payload.data.list.gratitudes', []);
            state.gratitudesList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        gratitudesListFailedAction: (state, action) => {
            state.gratitudesList.loading = false;
            state.gratitudesList.error = action.payload;
        },

        // create new gratitude
        createGratitudeRequestedAction: (state) => {
            state.createGratitude.loading = true;
        },

        createGratitudeSuccessAction: (state, action) => {
            state.createGratitude.loading = false;
            state.createGratitude.data = action.payload;
        },

        createGratitudeFailedAction: (state, action) => {
            state.createGratitude.loading = false;
            state.createGratitude.error = action.payload;
        },

        createGratitudeStoreClearAction: (state) => {
            state.createGratitude.loading = false;
            state.createGratitude.data = {};
            state.createGratitude.error = '';
        },

        // delete gratitudes
        deleteGratitudesRequestedAction: (state) => {
            state.deleteGratitudes.loading = true;
        },

        deleteGratitudesSuccessAction: (state, action) => {
            state.deleteGratitudes.loading = false;
            state.deleteGratitudes.data = action.payload;
        },

        deleteGratitudesFailedAction: (state, action) => {
            state.deleteGratitudes.loading = false;
            state.deleteGratitudes.error = action.payload;
        },

        deleteGratitudesStoreClearAction: (state) => {
            state.deleteGratitudes.loading = false;
            state.deleteGratitudes.data = {};
            state.deleteGratitudes.error = '';
        },

        // make gratitude active / in-active
        gratitudeVisibilityRequestedAction: (state) => {
            state.gratitudeVisibility.loading = true;
        },

        gratitudeVisibilitySuccessAction: (state, action) => {
            state.gratitudeVisibility.loading = false;
            state.gratitudeVisibility.data = action.payload;
        },

        gratitudeVisibilityFailedAction: (state, action) => {
            state.gratitudeVisibility.loading = false;
            state.gratitudeVisibility.error = action.payload;
        },

        gratitudeVisibilityStoreClearAction: (state) => {
            state.gratitudeVisibility.loading = false;
            state.gratitudeVisibility.data = {};
            state.gratitudeVisibility.error = '';
        },

        // edit a gratitude
        editGratitudeRequestedAction: (state) => {
            state.editGratitude.loading = true;
        },

        editGratitudeSuccessAction: (state, action) => {
            state.editGratitude.loading = false;
            state.editGratitude.data = action.payload;
        },

        editGratitudeFailedAction: (state, action) => {
            state.editGratitude.loading = false;
            state.editGratitude.error = action.payload;
        },

        editGratitudeStoreClearAction: (state) => {
            state.editGratitude.loading = false;
            state.editGratitude.data = {};
            state.editGratitude.error = '';
        }
    }
});

export default slice.reducer;

export const {
    // get list of gratitudes
    gratitudesListRequestedAction,
    gratitudesListSuccessAction,
    gratitudesListFailedAction,

    // create new gratitude
    createGratitudeRequestedAction,
    createGratitudeSuccessAction,
    createGratitudeFailedAction,
    createGratitudeStoreClearAction,

    // delete gratitudes
    deleteGratitudesRequestedAction,
    deleteGratitudesSuccessAction,
    deleteGratitudesFailedAction,
    deleteGratitudesStoreClearAction,

    // make gratitude active / in-active
    gratitudeVisibilityRequestedAction,
    gratitudeVisibilitySuccessAction,
    gratitudeVisibilityFailedAction,
    gratitudeVisibilityStoreClearAction,

    // edit a gratitude
    editGratitudeRequestedAction,
    editGratitudeSuccessAction,
    editGratitudeFailedAction,
    editGratitudeStoreClearAction
} = slice.actions;

export const getGratitudesList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/gratitude',
            method: 'GET',
            params: payload.params,
            onStart: gratitudesListRequestedAction.type,
            onSuccess: gratitudesListSuccessAction.type,
            onError: gratitudesListFailedAction.type
        })
    );
};

export const createGratitude = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/gratitude',
            method: 'POST',
            data: payload,
            onStart: createGratitudeRequestedAction.type,
            onSuccess: createGratitudeSuccessAction.type,
            onError: createGratitudeFailedAction.type
        })
    );
};

export const deleteGratitudes = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/gratitude`,
            method: 'DELETE',
            data: payload,
            onStart: deleteGratitudesRequestedAction.type,
            onSuccess: deleteGratitudesSuccessAction.type,
            onError: deleteGratitudesFailedAction.type
        })
    );
};

export const gratitudeVisibility = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/gratitude/visibility`,
            method: 'PUT',
            data: payload,
            onStart: gratitudeVisibilityRequestedAction.type,
            onSuccess: gratitudeVisibilitySuccessAction.type,
            onError: gratitudeVisibilityFailedAction.type
        })
    );
};

export const editGratitude = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/gratitude/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: editGratitudeRequestedAction.type,
            onSuccess: editGratitudeSuccessAction.type,
            onError: editGratitudeFailedAction.type
        })
    );
};
