import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'user',

    initialState: {
        usersList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        profileUser: {
            data: {},
            error: '',
            loading: false
        },
        userChallengeDetails: {
            data: {},
            error: '',
            loading: false
        },
        userGratitudeDetails: {
            data: {},
            error: '',
            loading: false
        },
        deleteUsers: {
            data: {},
            error: '',
            loading: false
        },
        userStatus: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get list of users
        usersListRequestedAction: (state) => {
            state.usersList.loading = true;
        },

        usersListSuccessAction: (state, action) => {
            state.usersList.loading = false;
            state.usersList.data = action.payload;
            state.usersList.list = get(action, 'payload.data.list.users', []);
            state.usersList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        usersListFailedAction: (state, action) => {
            state.usersList.loading = false;
            state.usersList.error = action.payload;
        },

        // get user details by id
        userDataByIdRequestedAction: (state) => {
            state.profileUser.loading = true;
        },

        userDataByIdSuccessAction: (state, action) => {
            state.profileUser.loading = false;
            state.profileUser.data = get(action, 'payload.data.user', {});
        },

        userDataByIdFailedAction: (state, action) => {
            state.profileUser.loading = false;
            state.profileUser.error = action.payload;
        },

        // get a user's challenge details
        userChallengeDetailsRequestedAction: (state) => {
            state.userChallengeDetails.loading = true;
        },

        userChallengeDetailsSuccessAction: (state, action) => {
            state.userChallengeDetails.loading = false;
            state.userChallengeDetails.data = action.payload;
        },

        userChallengeDetailsFailedAction: (state, action) => {
            state.userChallengeDetails.loading = false;
            state.userChallengeDetails.error = action.payload;
        },

        // get a user's gratitude details
        userGratitudeDetailsRequestedAction: (state) => {
            state.userGratitudeDetails.loading = true;
        },

        userGratitudeDetailsSuccessAction: (state, action) => {
            state.userGratitudeDetails.loading = false;
            state.userGratitudeDetails.data = action.payload;
        },

        userGratitudeDetailsFailedAction: (state, action) => {
            state.userGratitudeDetails.loading = false;
            state.userGratitudeDetails.error = action.payload;
        },

        // delete users
        deleteUsersRequestedAction: (state) => {
            state.deleteUsers.loading = true;
        },

        deleteUsersSuccessAction: (state, action) => {
            state.deleteUsers.loading = false;
            state.deleteUsers.data = action.payload;
        },

        deleteUsersFailedAction: (state, action) => {
            state.deleteUsers.loading = false;
            state.deleteUsers.error = action.payload;
        },

        deleteUsersStoreClearAction: (state) => {
            state.deleteUsers.loading = false;
            state.deleteUsers.data = {};
            state.deleteUsers.error = '';
        },

        // block / un-block user
        userStatusRequestedAction: (state) => {
            state.userStatus.loading = true;
        },

        userStatusSuccessAction: (state, action) => {
            state.userStatus.loading = false;
            state.userStatus.data = action.payload;
        },

        userStatusFailedAction: (state, action) => {
            state.userStatus.loading = false;
            state.userStatus.error = action.payload;
        },

        // enable / disable user's gratitude
        userGratitudeStatusRequestedAction: (state) => {
            state.userStatus.loading = true;
        },

        userGratitudeStatusSuccessAction: (state, action) => {
            state.userStatus.loading = false;
            state.userStatus.data = action.payload;
        },

        userGratitudeStatusFailedAction: (state, action) => {
            state.userStatus.loading = false;
            state.userStatus.error = action.payload;
        },

        userStatusStoreClearAction: (state) => {
            state.userStatus.loading = false;
            state.userStatus.data = {};
            state.userStatus.error = '';
        }
    }
});

export default slice.reducer;

export const {
    // get list of users
    usersListRequestedAction,
    usersListSuccessAction,
    usersListFailedAction,

    // get user details by id
    userDataByIdRequestedAction,
    userDataByIdSuccessAction,
    userDataByIdFailedAction,

    // get a user's challenge details
    userChallengeDetailsRequestedAction,
    userChallengeDetailsSuccessAction,
    userChallengeDetailsFailedAction,

    // get a user's gratitude details
    userGratitudeDetailsRequestedAction,
    userGratitudeDetailsSuccessAction,
    userGratitudeDetailsFailedAction,

    // delete users
    deleteUsersRequestedAction,
    deleteUsersSuccessAction,
    deleteUsersFailedAction,
    deleteUsersStoreClearAction,

    // block / un-block user
    userStatusRequestedAction,
    userStatusSuccessAction,
    userStatusFailedAction,

    // enable / disable user gratitude status
    userGratitudeStatusRequestedAction,
    userGratitudeStatusSuccessAction,
    userGratitudeStatusFailedAction,

    userStatusStoreClearAction
} = slice.actions;

export const getUsersList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/user',
            method: 'GET',
            params: payload.params,
            onStart: usersListRequestedAction.type,
            onSuccess: usersListSuccessAction.type,
            onError: usersListFailedAction.type
        })
    );
};

export const getUserById = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user/${payload.userId}`,
            method: 'GET',
            onStart: userDataByIdRequestedAction.type,
            onSuccess: userDataByIdSuccessAction.type,
            onError: userDataByIdFailedAction.type
        })
    );
};

export const getUserChallengeDetails = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user/challenge-data/${payload.userId}`,
            method: 'GET',
            onStart: userChallengeDetailsRequestedAction.type,
            onSuccess: userChallengeDetailsSuccessAction.type,
            onError: userChallengeDetailsFailedAction.type
        })
    );
};

export const getUserGratitudeDetails = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user/gratitude-data/${payload.userId}`,
            method: 'GET',
            onStart: userGratitudeDetailsRequestedAction.type,
            onSuccess: userGratitudeDetailsSuccessAction.type,
            onError: userGratitudeDetailsFailedAction.type
        })
    );
};

export const deleteUsers = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user`,
            method: 'DELETE',
            data: payload,
            onStart: deleteUsersRequestedAction.type,
            onSuccess: deleteUsersSuccessAction.type,
            onError: deleteUsersFailedAction.type
        })
    );
};

export const userStatus = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user/status/${payload.userId}`,
            method: 'PUT',
            data: payload.body,
            onStart: userStatusRequestedAction.type,
            onSuccess: userStatusSuccessAction.type,
            onError: userStatusFailedAction.type
        })
    );
};

export const userGratitudeStatus = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/user/gratitude-status/${payload.userId}`,
            method: 'PUT',
            data: payload.body,
            onStart: userGratitudeStatusRequestedAction.type,
            onSuccess: userGratitudeStatusSuccessAction.type,
            onError: userGratitudeStatusFailedAction.type
        })
    );
};
