import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'successStory',

    initialState: {
        successStoriesList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        createSuccessStory: {
            data: {},
            error: '',
            loading: false
        },
        deleteSuccessStories: {
            data: {},
            error: '',
            loading: false
        },
        successStoryVisibility: {
            data: {},
            error: '',
            loading: false
        },
        editSuccessStory: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get list of success stories
        successStoriesListRequestedAction: (state) => {
            state.successStoriesList.loading = true;
        },

        successStoriesListSuccessAction: (state, action) => {
            state.successStoriesList.loading = false;
            state.successStoriesList.data = action.payload;
            state.successStoriesList.list = get(action, 'payload.data.list.stories', []);
            state.successStoriesList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        successStoriesListFailedAction: (state, action) => {
            state.successStoriesList.loading = false;
            state.successStoriesList.error = action.payload;
        },

        // create new success story
        createSuccessStoryRequestedAction: (state) => {
            state.createSuccessStory.loading = true;
        },

        createSuccessStorySuccessAction: (state, action) => {
            state.createSuccessStory.loading = false;
            state.createSuccessStory.data = action.payload;
        },

        createSuccessStoryFailedAction: (state, action) => {
            state.createSuccessStory.loading = false;
            state.createSuccessStory.error = action.payload;
        },

        createSuccessStoryStoreClearAction: (state) => {
            state.createSuccessStory.loading = false;
            state.createSuccessStory.data = {};
            state.createSuccessStory.error = '';
        },

        // delete success stories
        deleteSuccessStoriesRequestedAction: (state) => {
            state.deleteSuccessStories.loading = true;
        },

        deleteSuccessStoriesSuccessAction: (state, action) => {
            state.deleteSuccessStories.loading = false;
            state.deleteSuccessStories.data = action.payload;
        },

        deleteSuccessStoriesFailedAction: (state, action) => {
            state.deleteSuccessStories.loading = false;
            state.deleteSuccessStories.error = action.payload;
        },

        deleteSuccessStoriesStoreClearAction: (state) => {
            state.deleteSuccessStories.loading = false;
            state.deleteSuccessStories.data = {};
            state.deleteSuccessStories.error = '';
        },

        // make successStory active / in-active
        successStoryVisibilityRequestedAction: (state) => {
            state.successStoryVisibility.loading = true;
        },

        successStoryVisibilitySuccessAction: (state, action) => {
            state.successStoryVisibility.loading = false;
            state.successStoryVisibility.data = action.payload;
        },

        successStoryVisibilityFailedAction: (state, action) => {
            state.successStoryVisibility.loading = false;
            state.successStoryVisibility.error = action.payload;
        },

        successStoryVisibilityStoreClearAction: (state) => {
            state.successStoryVisibility.loading = false;
            state.successStoryVisibility.data = {};
            state.successStoryVisibility.error = '';
        },

        // edit a success story
        editSuccessStoryRequestedAction: (state) => {
            state.editSuccessStory.loading = true;
        },

        editSuccessStorySuccessAction: (state, action) => {
            state.editSuccessStory.loading = false;
            state.editSuccessStory.data = action.payload;
        },

        editSuccessStoryFailedAction: (state, action) => {
            state.editSuccessStory.loading = false;
            state.editSuccessStory.error = action.payload;
        },

        editSuccessStoryStoreClearAction: (state) => {
            state.editSuccessStory.loading = false;
            state.editSuccessStory.data = {};
            state.editSuccessStory.error = '';
        }
    }
});

export default slice.reducer;

export const {
    // get list of success stories
    successStoriesListRequestedAction,
    successStoriesListSuccessAction,
    successStoriesListFailedAction,

    // create new success story
    createSuccessStoryRequestedAction,
    createSuccessStorySuccessAction,
    createSuccessStoryFailedAction,
    createSuccessStoryStoreClearAction,

    // delete success stories
    deleteSuccessStoriesRequestedAction,
    deleteSuccessStoriesSuccessAction,
    deleteSuccessStoriesFailedAction,
    deleteSuccessStoriesStoreClearAction,

    // make successStory active / in-active
    successStoryVisibilityRequestedAction,
    successStoryVisibilitySuccessAction,
    successStoryVisibilityFailedAction,
    successStoryVisibilityStoreClearAction,

    // edit a success story
    editSuccessStoryRequestedAction,
    editSuccessStorySuccessAction,
    editSuccessStoryFailedAction,
    editSuccessStoryStoreClearAction
} = slice.actions;

export const getSuccessStoriesList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/success-story',
            method: 'GET',
            params: payload.params,
            onStart: successStoriesListRequestedAction.type,
            onSuccess: successStoriesListSuccessAction.type,
            onError: successStoriesListFailedAction.type
        })
    );
};

export const createSuccessStory = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/success-story',
            method: 'POST',
            data: payload,
            onStart: createSuccessStoryRequestedAction.type,
            onSuccess: createSuccessStorySuccessAction.type,
            onError: createSuccessStoryFailedAction.type
        })
    );
};

export const deleteSuccessStories = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/success-story`,
            method: 'DELETE',
            data: payload,
            onStart: deleteSuccessStoriesRequestedAction.type,
            onSuccess: deleteSuccessStoriesSuccessAction.type,
            onError: deleteSuccessStoriesFailedAction.type
        })
    );
};

export const successStoryVisibility = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/success-story/visibility`,
            method: 'PUT',
            data: payload,
            onStart: successStoryVisibilityRequestedAction.type,
            onSuccess: successStoryVisibilitySuccessAction.type,
            onError: successStoryVisibilityFailedAction.type
        })
    );
};

export const editSuccessStory = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/success-story/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: editSuccessStoryRequestedAction.type,
            onSuccess: editSuccessStorySuccessAction.type,
            onError: editSuccessStoryFailedAction.type
        })
    );
};
