// assets
import { IconBolt, IconAward, IconBlockquote, IconMessage } from '@tabler/icons';

// constant
const icons = {
    IconBolt,
    IconAward,
    IconBlockquote,
    IconMessage
};

// ==============================|| USER MANAGEMENT MENU ITEMS ||============================== //

const pages = {
    id: 'app-management',
    title: 'App Management',
    caption: 'Manage content',
    type: 'group',
    children: [
        {
            id: 'challenge',
            title: 'Challenges',
            type: 'item',
            url: '/app-management/challenges',
            icon: icons.IconBolt,
            breadcrumbs: false
        },
        {
            id: 'gratitude',
            title: 'Gratitudes',
            type: 'item',
            url: '/app-management/gratitudes',
            icon: icons.IconAward,
            breadcrumbs: false
        },
        {
            id: 'quote',
            title: 'Quotes',
            type: 'item',
            url: '/app-management/quotes',
            icon: icons.IconBlockquote,
            breadcrumbs: false
        },
        {
            id: 'review',
            title: 'Success Stories',
            type: 'item',
            url: '/app-management/success-stories',
            icon: icons.IconMessage,
            breadcrumbs: false
        }
    ]
};

export default pages;
