import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('views/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('views/reset-password')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/signup/:token',
            element: <AuthRegister />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password/:token',
            element: <ResetPassword />
        }
    ]
};

export default AuthenticationRoutes;
