import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// user management routing
const User = Loadable(lazy(() => import('views/user-management/user')));
const Manager = Loadable(lazy(() => import('views/user-management/manager')));

// app management routing
const Challenge = Loadable(lazy(() => import('views/app-management/challenge')));
const Gratitude = Loadable(lazy(() => import('views/app-management/gratitude')));
const Quote = Loadable(lazy(() => import('views/app-management/quote')));
const Review = Loadable(lazy(() => import('views/app-management/success-story')));
const UserSettings = Loadable(lazy(() => import('views/user-setting')));
const UserProfile = Loadable(lazy(() => import('views/user-profile')));
const ManagerProfile = Loadable(lazy(() => import('views/manager-profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // ========|| Dashboard ||======== //
        {
            path: '/',
            element: <Dashboard />,
            attribute: 'dashboard'
        },
        {
            path: '/dashboard',
            element: <Dashboard />,
            attribute: 'dashboard'
        },
        // ========|| User Management ||======== //
        {
            path: '/user-management/users',
            element: <User />,
            attribute: 'user'
        },
        {
            path: '/user-management/user/:id',
            element: <UserProfile />,
            attribute: 'user'
        },
        {
            path: '/user-management/managers',
            element: <Manager />,
            attribute: 'manager'
        },
        {
            path: '/user-management/manager/:id',
            element: <ManagerProfile />,
            attribute: 'manager'
        },

        // ========|| App Management ||======== //
        {
            path: '/app-management/challenges',
            element: <Challenge />,
            attribute: 'challenge'
        },
        {
            path: '/app-management/gratitudes',
            element: <Gratitude />,
            attribute: 'gratitude'
        },
        {
            path: '/app-management/quotes',
            element: <Quote />,
            attribute: 'quote'
        },
        {
            path: '/app-management/success-stories',
            element: <Review />,
            attribute: 'success-story'
        },
        {
            path: '/settings',
            element: <UserSettings />
        }
    ]
};

export default MainRoutes;
