// assets
import { IconUser, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconUsers
};

// ==============================|| USER MANAGEMENT MENU ITEMS ||============================== //

const pages = {
    id: 'user-management',
    title: 'User Management',
    caption: 'Manage platform users',
    type: 'group',
    children: [
        {
            id: 'user',
            title: 'Users',
            type: 'item',
            url: '/user-management/users',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'manager',
            title: 'Managers',
            type: 'item',
            url: '/user-management/managers',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default pages;
