import { createSlice } from '@reduxjs/toolkit';

// project imports
import config from 'config';

const slice = createSlice({
    name: 'customization',
    initialState: {
        isOpen: [], // for active default menu
        fontFamily: config.fontFamily,
        borderRadius: config.borderRadius,
        opened: true
    },

    reducers: {
        menuOpenAction: (state, action) => {
            state.isOpen = [action.payload];
        },

        setMenuAction: (state, action) => {
            state.opened = action.payload;
        },

        setFontFamilyAction: (state, action) => {
            state.fontFamily = action.payload;
        },

        setBorderRadiusAction: (state, action) => {
            state.borderRadius = action.payload;
        }
    }
});

export default slice.reducer;

// Actions
export const { menuOpenAction, setMenuAction, setFontFamilyAction, setBorderRadiusAction } = slice.actions;
