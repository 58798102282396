import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { getAllowedMenus } from '../../../../utils/validateRoles';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const userData = useSelector((state) => state.auth.validateUser);

    const allowedMenuItems = getAllowedMenus(menuItem, userData.user);

    const navItems = allowedMenuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
