import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'challenge',

    initialState: {
        challengesList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        deleteChallenges: {
            data: {},
            error: '',
            loading: false
        },
        challengeVisibility: {
            data: {},
            error: '',
            loading: false
        },
        editChallenge: {
            data: {},
            error: '',
            loading: false
        },
        createChallenge: {
            data: {},
            error: '',
            loading: false
        },
        createSubChallenge: {
            data: {},
            error: '',
            loading: false
        },
        editSubChallenge: {
            data: {},
            error: '',
            loading: false
        },
        subChallengeVisibility: {
            data: {},
            error: '',
            loading: false
        },
        deleteSubChallenge: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get list of challenges
        challengeListRequestedAction: (state) => {
            state.challengesList.loading = true;
        },

        challengeListSuccessAction: (state, action) => {
            state.challengesList.loading = false;
            state.challengesList.data = action.payload;
            state.challengesList.list = get(action, 'payload.data.list.challenges', []);
            state.challengesList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        challengeListFailedAction: (state, action) => {
            state.challengesList.loading = false;
            state.challengesList.error = action.payload;
        },

        // delete challenges
        deleteChallengesRequestedAction: (state) => {
            state.deleteChallenges.loading = true;
        },

        deleteChallengesSuccessAction: (state, action) => {
            state.deleteChallenges.loading = false;
            state.deleteChallenges.data = action.payload;
        },

        deleteChallengesFailedAction: (state, action) => {
            state.deleteChallenges.loading = false;
            state.deleteChallenges.error = action.payload;
        },

        deleteChallengeStoreClearAction: (state) => {
            state.deleteChallenges.loading = false;
            state.deleteChallenges.data = {};
            state.deleteChallenges.error = '';
        },

        // make challenge active / in-active
        challengeVisibilityRequestedAction: (state) => {
            state.challengeVisibility.loading = true;
        },

        challengeVisibilitySuccessAction: (state, action) => {
            state.challengeVisibility.loading = false;
            state.challengeVisibility.data = action.payload;
        },

        challengeVisibilityFailedAction: (state, action) => {
            state.challengeVisibility.loading = false;
            state.challengeVisibility.error = action.payload;
        },

        challengeVisibilityStoreClearAction: (state) => {
            state.challengeVisibility.loading = false;
            state.challengeVisibility.data = {};
            state.challengeVisibility.error = '';
        },

        // edit a challenge
        editChallengeRequestedAction: (state) => {
            state.editChallenge.loading = true;
        },

        editChallengeSuccessAction: (state, action) => {
            state.editChallenge.loading = false;
            state.editChallenge.data = action.payload;
        },

        editChallengeFailedAction: (state, action) => {
            state.editChallenge.loading = false;
            state.editChallenge.error = action.payload;
        },

        editChallengeStoreClearAction: (state) => {
            state.editChallenge.loading = false;
            state.editChallenge.data = {};
            state.editChallenge.error = '';
        },

        // create new challenge
        createChallengeRequestedAction: (state) => {
            state.createChallenge.loading = true;
        },

        createChallengeSuccessAction: (state, action) => {
            state.createChallenge.loading = false;
            state.createChallenge.data = action.payload;
        },

        createChallengeFailedAction: (state, action) => {
            state.createChallenge.loading = false;
            state.createChallenge.error = action.payload;
        },

        createChallengeStoreClearAction: (state) => {
            state.createChallenge.loading = false;
            state.createChallenge.data = {};
            state.createChallenge.error = '';
        },

        // delete sub challenges
        deleteSubChallengeRequestedAction: (state) => {
            state.deleteSubChallenge.loading = true;
        },

        deleteSubChallengeSuccessAction: (state, action) => {
            state.deleteSubChallenge.loading = false;
            state.deleteSubChallenge.data = action.payload;
        },

        deleteSubChallengeFailedAction: (state, action) => {
            state.deleteSubChallenge.loading = false;
            state.deleteSubChallenge.error = action.payload;
        },

        deleteSubChallengeStoreClearAction: (state) => {
            state.deleteSubChallenge.loading = false;
            state.deleteSubChallenge.data = {};
            state.deleteSubChallenge.error = '';
        },

        // make sub challenge active / in-active
        subChallengeVisibilityRequestedAction: (state) => {
            state.subChallengeVisibility.loading = true;
        },

        subChallengeVisibilitySuccessAction: (state, action) => {
            state.subChallengeVisibility.loading = false;
            state.subChallengeVisibility.data = action.payload;
        },

        subChallengeVisibilityFailedAction: (state, action) => {
            state.subChallengeVisibility.loading = false;
            state.subChallengeVisibility.error = action.payload;
        },

        subChallengeVisibilityStoreClearAction: (state) => {
            state.subChallengeVisibility.loading = false;
            state.subChallengeVisibility.data = {};
            state.subChallengeVisibility.error = '';
        },

        // edit a sub challenge
        editSubChallengeRequestedAction: (state) => {
            state.editSubChallenge.loading = true;
        },

        editSubChallengeSuccessAction: (state, action) => {
            state.editSubChallenge.loading = false;
            state.editSubChallenge.data = action.payload;
        },

        editSubChallengeFailedAction: (state, action) => {
            state.editSubChallenge.loading = false;
            state.editSubChallenge.error = action.payload;
        },

        editSubChallengeStoreClearAction: (state) => {
            state.editSubChallenge.loading = false;
            state.editSubChallenge.data = {};
            state.editSubChallenge.error = '';
        },

        // create new sub challenge
        createSubChallengeRequestedAction: (state) => {
            state.createSubChallenge.loading = true;
        },

        createSubChallengeSuccessAction: (state, action) => {
            state.createSubChallenge.loading = false;
            state.createSubChallenge.data = action.payload;
        },

        createSubChallengeFailedAction: (state, action) => {
            state.createSubChallenge.loading = false;
            state.createSubChallenge.error = action.payload;
        },

        createSubChallengeStoreClearAction: (state) => {
            state.createSubChallenge.loading = false;
            state.createSubChallenge.data = {};
            state.createSubChallenge.error = '';
        }
    }
});

export default slice.reducer;

export const {
    // get list of challenges
    challengeListRequestedAction,
    challengeListSuccessAction,
    challengeListFailedAction,

    // delete challenges
    deleteChallengesRequestedAction,
    deleteChallengesSuccessAction,
    deleteChallengesFailedAction,
    deleteChallengeStoreClearAction,

    // make challenge active / in-active
    challengeVisibilityRequestedAction,
    challengeVisibilitySuccessAction,
    challengeVisibilityFailedAction,
    challengeVisibilityStoreClearAction,

    // edit a challenge
    editChallengeRequestedAction,
    editChallengeSuccessAction,
    editChallengeFailedAction,
    editChallengeStoreClearAction,

    // create new challenge
    createChallengeRequestedAction,
    createChallengeSuccessAction,
    createChallengeFailedAction,
    createChallengeStoreClearAction,

    // delete sub challenges
    deleteSubChallengeRequestedAction,
    deleteSubChallengeSuccessAction,
    deleteSubChallengeFailedAction,
    deleteSubChallengeStoreClearAction,

    // make sub challenge active / in-active
    subChallengeVisibilityRequestedAction,
    subChallengeVisibilitySuccessAction,
    subChallengeVisibilityFailedAction,
    subChallengeVisibilityStoreClearAction,

    // edit a sub challenge
    editSubChallengeRequestedAction,
    editSubChallengeSuccessAction,
    editSubChallengeFailedAction,
    editSubChallengeStoreClearAction,

    // create new sub challenge
    createSubChallengeRequestedAction,
    createSubChallengeSuccessAction,
    createSubChallengeFailedAction,
    createSubChallengeStoreClearAction
} = slice.actions;

export const getChallengesList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/challenge',
            method: 'GET',
            params: payload.params,
            onStart: challengeListRequestedAction.type,
            onSuccess: challengeListSuccessAction.type,
            onError: challengeListFailedAction.type
        })
    );
};

export const deleteChallenges = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge`,
            method: 'DELETE',
            data: payload,
            onStart: deleteChallengesRequestedAction.type,
            onSuccess: deleteChallengesSuccessAction.type,
            onError: deleteChallengesFailedAction.type
        })
    );
};

export const challengeVisibility = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/visibility`,
            method: 'PUT',
            data: payload,
            onStart: challengeVisibilityRequestedAction.type,
            onSuccess: challengeVisibilitySuccessAction.type,
            onError: challengeVisibilityFailedAction.type
        })
    );
};

export const editChallenge = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: editChallengeRequestedAction.type,
            onSuccess: editChallengeSuccessAction.type,
            onError: editChallengeFailedAction.type
        })
    );
};

export const createChallenge = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge`,
            method: 'POST',
            data: payload,
            onStart: createChallengeRequestedAction.type,
            onSuccess: createChallengeSuccessAction.type,
            onError: createChallengeFailedAction.type
        })
    );
};

export const deleteSubChallenge = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/sub-challenge/${payload.id}`,
            method: 'DELETE',
            onStart: deleteSubChallengeRequestedAction.type,
            onSuccess: deleteSubChallengeSuccessAction.type,
            onError: deleteSubChallengeFailedAction.type
        })
    );
};

export const subChallengeVisibility = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/sub-challenge/visibility/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: subChallengeVisibilityRequestedAction.type,
            onSuccess: subChallengeVisibilitySuccessAction.type,
            onError: subChallengeVisibilityFailedAction.type
        })
    );
};

export const editSubChallenge = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/sub-challenge/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: editSubChallengeRequestedAction.type,
            onSuccess: editSubChallengeSuccessAction.type,
            onError: editSubChallengeFailedAction.type
        })
    );
};

export const createSubChallenge = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/challenge/sub-challenge/${payload.id}`,
            method: 'POST',
            data: payload.body,
            onStart: createSubChallengeRequestedAction.type,
            onSuccess: createSubChallengeSuccessAction.type,
            onError: createSubChallengeFailedAction.type
        })
    );
};
