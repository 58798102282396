import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'manager',

    initialState: {
        createManager: {
            data: {},
            error: '',
            loading: false
        },
        managersList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        profileManager: {
            data: {},
            error: '',
            loading: false
        },
        editProfile: {
            data: {},
            error: '',
            loading: false
        },
        updatePassword: {
            data: {},
            error: '',
            loading: false
        },
        forgotPassword: {
            data: {},
            error: '',
            loading: false
        },
        verifyResetPasswordToken: {
            data: {},
            error: '',
            loading: false
        },
        resetPassword: {
            data: {},
            error: '',
            loading: false
        },
        deleteManagers: {
            data: {},
            error: '',
            loading: false
        },
        managerStatus: {
            data: {},
            error: '',
            loading: false
        },
        canManageRoles: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // create manager
        createManagerRequestedAction: (state) => {
            state.createManager.loading = true;
        },

        createManagerSuccessAction: (state, action) => {
            state.createManager.loading = false;
            state.createManager.data = action.payload;
        },

        createManagerFailedAction: (state, action) => {
            state.createManager.loading = false;
            state.createManager.error = action.payload;
        },

        createManagerStoreClearAction: (state) => {
            state.createManager.loading = false;
            state.createManager.data = {};
            state.createManager.error = '';
        },

        // get list of managers
        managersListRequestedAction: (state) => {
            state.managersList.loading = true;
        },

        managersListSuccessAction: (state, action) => {
            state.managersList.loading = false;
            state.managersList.data = action.payload;
            state.managersList.list = get(action, 'payload.data.list.managers', []);
            state.managersList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        managersListFailedAction: (state, action) => {
            state.managersList.loading = false;
            state.managersList.error = action.payload;
        },

        // get manager details by id
        managerDataByIdRequestedAction: (state) => {
            state.profileManager.loading = true;
        },

        managerDataByIdSuccessAction: (state, action) => {
            state.profileManager.loading = false;
            state.profileManager.data = get(action, 'payload.data.manager', {});
        },

        managerDataByIdFailedAction: (state, action) => {
            state.profileManager.loading = false;
            state.profileManager.error = action.payload;
        },

        // edit profile
        editProfileRequestedAction: (state) => {
            state.editProfile.loading = true;
        },

        editProfileSuccessAction: (state, action) => {
            state.editProfile.loading = false;
            state.editProfile.data = action.payload;
        },

        editProfileFailedAction: (state, action) => {
            state.editProfile.loading = false;
            state.editProfile.error = action.payload;
        },

        editProfileStoreClearAction: (state) => {
            state.editProfile.loading = false;
            state.editProfile.data = {};
            state.editProfile.error = '';
        },

        // update password
        updatePasswordRequestedAction: (state) => {
            state.updatePassword.loading = true;
        },

        updatePasswordSuccessAction: (state, action) => {
            state.updatePassword.loading = false;
            state.updatePassword.data = action.payload;
        },

        updatePasswordFailedAction: (state, action) => {
            state.updatePassword.loading = false;
            state.updatePassword.error = action.payload;
        },

        updatePasswordStoreClearAction: (state) => {
            state.updatePassword.loading = false;
            state.updatePassword.data = {};
            state.updatePassword.error = '';
        },

        // forgot password
        forgotPasswordRequestAction: (state) => {
            state.forgotPassword.loading = true;
        },

        forgotPasswordRequestSuccessAction: (state, action) => {
            state.forgotPassword.loading = false;
            state.forgotPassword.data = action.payload;
        },

        forgotPasswordRequestFailedAction: (state, action) => {
            state.forgotPassword.loading = false;
            state.forgotPassword.error = action.payload;
        },

        forgotPasswordStoreClearAction: (state) => {
            state.forgotPassword.loading = false;
            state.forgotPassword.data = {};
            state.forgotPassword.error = '';
        },

        // verify reset password token
        verifyResetPasswordTokenRequestAction: (state) => {
            state.verifyResetPasswordToken.loading = true;
        },

        verifyResetPasswordTokenRequestSuccessAction: (state, action) => {
            state.verifyResetPasswordToken.loading = false;
            state.verifyResetPasswordToken.data = action.payload;
        },

        verifyResetPasswordTokenRequestFailedAction: (state, action) => {
            state.verifyResetPasswordToken.loading = false;
            state.verifyResetPasswordToken.error = action.payload;
        },

        // reset password
        resetPasswordRequestedAction: (state) => {
            state.resetPassword.loading = true;
        },

        resetPasswordSuccessAction: (state, action) => {
            state.resetPassword.loading = false;
            state.resetPassword.data = action.payload;
        },

        resetPasswordFailedAction: (state, action) => {
            state.resetPassword.loading = false;
            state.resetPassword.error = action.payload;
        },

        resetPasswordStoreClearAction: (state) => {
            state.resetPassword.loading = false;
            state.resetPassword.data = {};
            state.resetPassword.error = '';
        },

        // delete managers
        deleteManagersRequestedAction: (state) => {
            state.deleteManagers.loading = true;
        },

        deleteManagersSuccessAction: (state, action) => {
            state.deleteManagers.loading = false;
            state.deleteManagers.data = action.payload;
        },

        deleteManagersFailedAction: (state, action) => {
            state.deleteManagers.loading = false;
            state.deleteManagers.error = action.payload;
        },

        deleteManagersStoreClearAction: (state) => {
            state.deleteManagers.loading = false;
            state.deleteManagers.data = {};
            state.deleteManagers.error = '';
        },

        // block / un-block manager
        managerStatusRequestedAction: (state) => {
            state.managerStatus.loading = true;
        },

        managerStatusSuccessAction: (state, action) => {
            state.managerStatus.loading = false;
            state.managerStatus.data = action.payload;
        },

        managerStatusFailedAction: (state, action) => {
            state.managerStatus.loading = false;
            state.managerStatus.error = action.payload;
        },

        managerStatusStoreClearAction: (state) => {
            state.managerStatus.loading = false;
            state.managerStatus.data = {};
            state.managerStatus.error = '';
        },

        // get roles that logged in user can manage
        canManageRoleRequestedAction: (state) => {
            state.canManageRoles.loading = true;
        },

        canManageRoleSuccessAction: (state, action) => {
            state.canManageRoles.loading = false;
            state.canManageRoles.data = action.payload;
        },

        canManageRoleFailedAction: (state, action) => {
            state.canManageRoles.loading = false;
            state.canManageRoles.error = action.payload;
        }
    }
});

export default slice.reducer;

export const {
    // create manager
    createManagerRequestedAction,
    createManagerSuccessAction,
    createManagerFailedAction,
    createManagerStoreClearAction,

    // get list of managers
    managersListRequestedAction,
    managersListSuccessAction,
    managersListFailedAction,

    // get manager details by id
    managerDataByIdRequestedAction,
    managerDataByIdSuccessAction,
    managerDataByIdFailedAction,

    // edit profile
    editProfileRequestedAction,
    editProfileSuccessAction,
    editProfileFailedAction,
    editProfileStoreClearAction,

    // update password
    updatePasswordRequestedAction,
    updatePasswordSuccessAction,
    updatePasswordFailedAction,
    updatePasswordStoreClearAction,

    // forgot password
    forgotPasswordRequestAction,
    forgotPasswordRequestSuccessAction,
    forgotPasswordRequestFailedAction,
    forgotPasswordStoreClearAction,

    // verify reset password token
    verifyResetPasswordTokenRequestAction,
    verifyResetPasswordTokenRequestSuccessAction,
    verifyResetPasswordTokenRequestFailedAction,

    // reset password
    resetPasswordRequestedAction,
    resetPasswordSuccessAction,
    resetPasswordFailedAction,
    resetPasswordStoreClearAction,

    // delete managers
    deleteManagersRequestedAction,
    deleteManagersSuccessAction,
    deleteManagersFailedAction,
    deleteManagersStoreClearAction,

    // block / un-block manager
    managerStatusRequestedAction,
    managerStatusSuccessAction,
    managerStatusFailedAction,
    managerStatusStoreClearAction,

    // get roles that logged in user can manage
    canManageRoleRequestedAction,
    canManageRoleSuccessAction,
    canManageRoleFailedAction
} = slice.actions;

export const createManager = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/manager',
            method: 'POST',
            data: payload,
            onStart: createManagerRequestedAction.type,
            onSuccess: createManagerSuccessAction.type,
            onError: createManagerFailedAction.type
        })
    );
};

export const getManagersList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/manager',
            method: 'GET',
            params: payload.params,
            onStart: managersListRequestedAction.type,
            onSuccess: managersListSuccessAction.type,
            onError: managersListFailedAction.type
        })
    );
};

export const getManagerById = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/${payload.userId}`,
            method: 'GET',
            onStart: managerDataByIdRequestedAction.type,
            onSuccess: managerDataByIdSuccessAction.type,
            onError: managerDataByIdFailedAction.type
        })
    );
};

export const editProfile = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/edit-profile`,
            method: 'PUT',
            data: payload,
            onStart: editProfileRequestedAction.type,
            onSuccess: editProfileSuccessAction.type,
            onError: editProfileFailedAction.type
        })
    );
};

export const updatePassword = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/change-password`,
            method: 'PUT',
            data: payload,
            onStart: updatePasswordRequestedAction.type,
            onSuccess: updatePasswordSuccessAction.type,
            onError: updatePasswordFailedAction.type
        })
    );
};

export const forgotPassword = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/manager/forgot-password',
            method: 'PUT',
            data: payload,
            onStart: forgotPasswordRequestAction.type,
            onSuccess: forgotPasswordRequestSuccessAction.type,
            onError: forgotPasswordRequestFailedAction.type
        })
    );
};

export const verifyResetPasswordToken = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/verify-reset-password-token/${payload.token}`,
            method: 'PUT',
            onStart: verifyResetPasswordTokenRequestAction.type,
            onSuccess: verifyResetPasswordTokenRequestSuccessAction.type,
            onError: verifyResetPasswordTokenRequestFailedAction.type
        })
    );
};

export const resetPassword = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/reset-password/${payload.token}`,
            method: 'PUT',
            data: payload.body,
            onStart: resetPasswordRequestedAction.type,
            onSuccess: resetPasswordSuccessAction.type,
            onError: resetPasswordFailedAction.type
        })
    );
};

export const deleteManagers = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager`,
            method: 'DELETE',
            data: payload,
            onStart: deleteManagersRequestedAction.type,
            onSuccess: deleteManagersSuccessAction.type,
            onError: deleteManagersFailedAction.type
        })
    );
};

export const managerStatus = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/status/${payload.managerId}`,
            method: 'PUT',
            data: payload.body,
            onStart: managerStatusRequestedAction.type,
            onSuccess: managerStatusSuccessAction.type,
            onError: managerStatusFailedAction.type
        })
    );
};

export const getCanManageRoles = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/manager/can-manage`,
            method: 'GET',
            onStart: canManageRoleRequestedAction.type,
            onSuccess: canManageRoleSuccessAction.type,
            onError: canManageRoleFailedAction.type
        })
    );
};
