import { combineReducers } from 'redux';

// reducer import
import authReducer from './reducers/auth.reducer';
import userReducer from './reducers/user.reducer';
import quoteReducer from './reducers/quote.reducer';
import commonReducer from './reducers/common.reducer';
import managerReducer from './reducers/manager.reducer';
import dashboardReducer from './reducers/dashboard.reducer';
import challengeReducer from './reducers/challenge.reducer';
import gratitudeReducer from './reducers/gratitude.reducer';
import successStoryReducer from './reducers/success-story.reducer';
import customizationReducer from './reducers/customization.reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    quote: quoteReducer,
    common: commonReducer,
    manager: managerReducer,
    dashboard: dashboardReducer,
    challenge: challengeReducer,
    gratitude: gratitudeReducer,
    successStory: successStoryReducer,
    customization: customizationReducer
});

export default reducer;
