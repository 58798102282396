import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'auth',

    initialState: {
        login: {
            data: {},
            error: '',
            loading: false
        },
        logout: {
            error: '',
            loading: false
        },
        validateUser: {
            user: {},
            error: '',
            loading: false,
            isCookieVerified: false
        },
        registerTokenValidation: {
            data: {},
            error: '',
            loading: false
        },
        registerUser: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        setUserDataAction: (state, action) => {
            state.validateUser.user = action.payload;
        },

        // login
        loginRequestedAction: (state) => {
            state.login.loading = true;
        },

        loginSuccessAction: (state, action) => {
            state.login.loading = false;
            state.login.data = action.payload;
            state.validateUser.user = get(action, 'payload.data.user', {});
        },

        loginFailedAction: (state, action) => {
            state.login.loading = false;
            state.login.error = action.payload;
        },

        loginStoreClearAction: (state) => {
            state.login.loading = false;
            state.login.data = {};
            state.login.error = '';
        },

        // logout
        logoutRequestedAction: (state) => {
            state.validateUser.user = {};
        },

        logoutSuccessAction: (state) => {
            state.logout.loading = false;
            state.validateUser.user = {};
        },

        logoutFailedAction: (state, action) => {
            state.logout.loading = false;
            state.logout.error = action.payload;
        },

        logoutStoreClearAction: (state) => {
            state.logout.loading = false;
            state.logout.data = {};
            state.logout.error = '';
        },

        // validate user
        validateUserRequestAction: (state) => {
            state.validateUser.loading = true;
        },

        validateUserSuccessAction: (state, action) => {
            state.validateUser.loading = false;
            state.validateUser.isCookieVerified = true;
            state.validateUser.user = get(action, 'payload.data.user', {});
        },

        validateUserFailedAction: (state, action) => {
            state.validateUser.loading = false;
            state.validateUser.isCookieVerified = true;
            state.validateUser.user = {};
            state.validateUser.error = action.payload;
        },

        // register token validation
        registerTokenValidateRequestAction: (state) => {
            state.registerTokenValidation.loading = true;
        },

        registerTokenValidateSuccessAction: (state, action) => {
            state.registerTokenValidation.loading = false;
            state.registerTokenValidation.data = action.payload;
        },

        registerTokenValidateFailedAction: (state, action) => {
            state.registerTokenValidation.loading = false;
            state.registerTokenValidation.error = action.payload;
        },

        // user registration
        registerUserRequestAction: (state) => {
            state.registerUser.loading = true;
        },

        registerUserRequestSuccessAction: (state, action) => {
            state.registerUser.loading = false;
            state.registerUser.data = action.payload;
        },

        registerUserRequestFailedAction: (state, action) => {
            state.registerUser.loading = false;
            state.registerUser.error = action.payload;
        },

        registerUserStoreClearAction: (state) => {
            state.registerUser.loading = false;
            state.registerUser.data = {};
            state.registerUser.error = '';
        }
    }
});

export default slice.reducer;

export const {
    setUserDataAction,

    // login
    loginRequestedAction,
    loginSuccessAction,
    loginFailedAction,
    loginStoreClearAction,

    // logout
    logoutRequestedAction,
    logoutSuccessAction,
    logoutFailedAction,
    logoutStoreClearAction,

    // vaildate user
    validateUserRequestAction,
    validateUserSuccessAction,
    validateUserFailedAction,

    // register token validation
    registerTokenValidateRequestAction,
    registerTokenValidateSuccessAction,
    registerTokenValidateFailedAction,

    // user registration
    registerUserRequestAction,
    registerUserRequestSuccessAction,
    registerUserRequestFailedAction,
    registerUserStoreClearAction
} = slice.actions;

export const login = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/login',
            method: 'POST',
            data: payload,
            onStart: loginRequestedAction.type,
            onSuccess: loginSuccessAction.type,
            onError: loginFailedAction.type
        })
    );
};

export const logout = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/logout',
            method: 'GET',
            onStart: logoutRequestedAction.type,
            onSuccess: logoutSuccessAction.type,
            onError: logoutFailedAction.type
        })
    );
};

export const validateUser = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/validate-user',
            method: 'GET',
            onStart: validateUserRequestAction.type,
            onSuccess: validateUserSuccessAction.type,
            onError: validateUserFailedAction.type
        })
    );
};

export const validateRegisterToken = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/verify-email-token/${payload.token}`,
            method: 'PUT',
            onStart: registerTokenValidateRequestAction.type,
            onSuccess: registerTokenValidateSuccessAction.type,
            onError: registerTokenValidateFailedAction.type
        })
    );
};

export const registerUser = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/register/${payload.token}`,
            method: 'PUT',
            data: payload.body,
            onStart: registerUserRequestAction.type,
            onSuccess: registerUserRequestSuccessAction.type,
            onError: registerUserRequestFailedAction.type
        })
    );
};
