import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// * Get available attributes from the roles list
export const getUserAttributes = (userDetails) => {
    const userRoleDetails = get(userDetails, 'role', {});

    const availableAttributes = get(userRoleDetails, 'attributes', []);

    const attributesNameList = availableAttributes.map((attribute) => attribute.name);

    return { availableAttributes, attributesNameList };
};

export const getAllowedMenus = (navigation, userData) => {
    const { attributesNameList } = getUserAttributes(userData);

    const validRoutes = [];

    navigation.items.forEach((parent) => {
        const children = get(parent, 'children', []);
        const validAttributes = [];

        children.forEach((child) => {
            if (attributesNameList.includes(child.id)) {
                validAttributes.push(child);
            }
        });

        if (!isEmpty(validAttributes)) {
            validRoutes.push({ ...parent, children: validAttributes });
        }
    });

    return { items: validRoutes };
};

export const getAvailableRoutes = (routes, userData) => {
    const { attributesNameList } = getUserAttributes(userData);

    const validRoutes = routes.children.filter((child) => isEmpty(child.attribute) || attributesNameList.includes(child.attribute));

    return { ...routes, children: validRoutes };
};
