import { useEffect } from 'react';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// project imports
import NotFound from './NotFound';
import config from 'config';
import { validateUser } from 'store/reducers/auth.reducer';
import { getAvailableRoutes } from '../utils/validateRoles';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const userData = useSelector((state) => state.auth.validateUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (!userData.isCookieVerified) {
            dispatch(validateUser());
        } else if (userData.isCookieVerified) {
            if (location.pathname === '/') {
                navigate('/login');
            }
        }
    }, [dispatch, location, navigate, userData]);

    return useRoutes(
        [
            !isEmpty(userData.user) && getAvailableRoutes(MainRoutes, userData.user),
            AuthenticationRoutes,
            userData.isCookieVerified && NotFound
        ],
        config.basename
    );
}
