import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'common',

    initialState: {
        alert: {
            open: false,
            message: '',
            severity: ''
        }
    },

    reducers: {
        // alerts
        openAlertRequestedAction: (state, action) => {
            state.alert.open = true;
            state.alert.message = action.payload.message;
            state.alert.severity = action.payload.severity;
        },

        closeAlertRequestedAction: (state) => {
            state.alert.open = false;
            state.alert.message = '';
            state.alert.severity = '';
        }
    }
});

export default slice.reducer;

export const {
    // alerts
    openAlertRequestedAction,
    closeAlertRequestedAction
} = slice.actions;
