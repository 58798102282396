import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

// project imports
import { apiCallBegan } from '../api';

const slice = createSlice({
    name: 'quote',

    initialState: {
        quotesList: {
            list: [],
            pagination: {},
            error: '',
            loading: false
        },
        createQuote: {
            data: {},
            error: '',
            loading: false
        },
        deleteQuotes: {
            data: {},
            error: '',
            loading: false
        },
        quoteVisibility: {
            data: {},
            error: '',
            loading: false
        },
        editQuote: {
            data: {},
            error: '',
            loading: false
        }
    },

    reducers: {
        // get list of quotes
        quotesListRequestedAction: (state) => {
            state.quotesList.loading = true;
        },

        quotesListSuccessAction: (state, action) => {
            state.quotesList.loading = false;
            state.quotesList.data = action.payload;
            state.quotesList.list = get(action, 'payload.data.list.quotes', []);
            state.quotesList.pagination = get(action, 'payload.data.list.metadata[0]', {});
        },

        quotesListFailedAction: (state, action) => {
            state.quotesList.loading = false;
            state.quotesList.error = action.payload;
        },

        // create new quote
        createQuoteRequestedAction: (state) => {
            state.createQuote.loading = true;
        },

        createQuoteSuccessAction: (state, action) => {
            state.createQuote.loading = false;
            state.createQuote.data = action.payload;
        },

        createQuoteFailedAction: (state, action) => {
            state.createQuote.loading = false;
            state.createQuote.error = action.payload;
        },

        createQuoteStoreClearAction: (state) => {
            state.createQuote.loading = false;
            state.createQuote.data = {};
            state.createQuote.error = '';
        },

        // delete quotes
        deleteQuotesRequestedAction: (state) => {
            state.deleteQuotes.loading = true;
        },

        deleteQuotesSuccessAction: (state, action) => {
            state.deleteQuotes.loading = false;
            state.deleteQuotes.data = action.payload;
        },

        deleteQuotesFailedAction: (state, action) => {
            state.deleteQuotes.loading = false;
            state.deleteQuotes.error = action.payload;
        },

        deleteQuotesStoreClearAction: (state) => {
            state.deleteQuotes.loading = false;
            state.deleteQuotes.data = {};
            state.deleteQuotes.error = '';
        },

        // make quote active / in-active
        quoteVisibilityRequestedAction: (state) => {
            state.quoteVisibility.loading = true;
        },

        quoteVisibilitySuccessAction: (state, action) => {
            state.quoteVisibility.loading = false;
            state.quoteVisibility.data = action.payload;
        },

        quoteVisibilityFailedAction: (state, action) => {
            state.quoteVisibility.loading = false;
            state.quoteVisibility.error = action.payload;
        },

        quoteVisibilityStoreClearAction: (state) => {
            state.quoteVisibility.loading = false;
            state.quoteVisibility.data = {};
            state.quoteVisibility.error = '';
        },

        // edit a quote
        editQuoteRequestedAction: (state) => {
            state.editQuote.loading = true;
        },

        editQuoteSuccessAction: (state, action) => {
            state.editQuote.loading = false;
            state.editQuote.data = action.payload;
        },

        editQuoteFailedAction: (state, action) => {
            state.editQuote.loading = false;
            state.editQuote.error = action.payload;
        },

        editQuoteStoreClearAction: (state) => {
            state.editQuote.loading = false;
            state.editQuote.data = {};
            state.editQuote.error = '';
        }
    }
});

export default slice.reducer;

export const {
    // get list of quotes
    quotesListRequestedAction,
    quotesListSuccessAction,
    quotesListFailedAction,

    // create new quote
    createQuoteRequestedAction,
    createQuoteSuccessAction,
    createQuoteFailedAction,
    createQuoteStoreClearAction,

    // delete quotes
    deleteQuotesRequestedAction,
    deleteQuotesSuccessAction,
    deleteQuotesFailedAction,
    deleteQuotesStoreClearAction,

    // make quote active / in-active
    quoteVisibilityRequestedAction,
    quoteVisibilitySuccessAction,
    quoteVisibilityFailedAction,
    quoteVisibilityStoreClearAction,

    // edit a quote
    editQuoteRequestedAction,
    editQuoteSuccessAction,
    editQuoteFailedAction,
    editQuoteStoreClearAction
} = slice.actions;

export const getQuotesList = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/quote',
            method: 'GET',
            params: payload.params,
            onStart: quotesListRequestedAction.type,
            onSuccess: quotesListSuccessAction.type,
            onError: quotesListFailedAction.type
        })
    );
};

export const createQuote = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: '/quote',
            method: 'POST',
            data: payload,
            onStart: createQuoteRequestedAction.type,
            onSuccess: createQuoteSuccessAction.type,
            onError: createQuoteFailedAction.type
        })
    );
};

export const deleteQuotes = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/quote`,
            method: 'DELETE',
            data: payload,
            onStart: deleteQuotesRequestedAction.type,
            onSuccess: deleteQuotesSuccessAction.type,
            onError: deleteQuotesFailedAction.type
        })
    );
};

export const quoteVisibility = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/quote/visibility`,
            method: 'PUT',
            data: payload,
            onStart: quoteVisibilityRequestedAction.type,
            onSuccess: quoteVisibilitySuccessAction.type,
            onError: quoteVisibilityFailedAction.type
        })
    );
};

export const editQuote = (payload) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `/quote/${payload.id}`,
            method: 'PUT',
            data: payload.body,
            onStart: editQuoteRequestedAction.type,
            onSuccess: editQuoteSuccessAction.type,
            onError: editQuoteFailedAction.type
        })
    );
};
